
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { useStore } from "vuex";
import { translate } from "@/hooks/TranslateService";
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n();
    const submitButton1 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      user_name: Yup.string().required().label("Username"),
    });

    const changeEmail = Yup.object().shape({
      email: Yup.string().required(translate("EMAIL_REQUIRED")).email(translate("VALID_EMAIL")).label("Email")
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required(translate("CONFIRM_PASSWORD_REQUIRED")).label(translate("CONFIRM_PASSWORD")),
      newpassword: Yup.string()
        .min(6, translate("REGISTRATION_PASSWORD_PLACEHOLDER"))
        .required(translate("PASSWORD_REQUIRED"))
        .label("Password"),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], translate("PASSWORD_MUST_MATCH"))
        .label("Password Confirmation"),
    });

    const updateUserProfile = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        if (values.ruolo_aziendale !== null && values.ruolo_aziendale.trim() === "") {
          values.ruolo_aziendale = null;
        }
        if (values.telefono !== null && values.telefono.trim() === "") {
          values.telefono = null;
        }

        values.id = accountInfo.userProfile.id;
        store
          .dispatch(Actions.UPDATE_USER_PROFILE, values)
          .then(() => {
            let simulatedUser = JSON.parse(localStorage.getItem("simulate_user") as string);
            simulatedUser.user_name = values.user_name;
            simulatedUser.ruolo_aziendale = values.ruolo_aziendale;
            simulatedUser.telefono = values.telefono;
            localStorage.setItem("simulate_user", JSON.stringify(simulatedUser));

            successMessage(t("FEEDBACK_USERNAME_CHANGE_SUCCESSFUL"));
          })
          .finally(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const updateEmail = (values, { resetForm }) => {
      if (updateEmailButton.value) {
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        values.id = accountInfo.userProfile.id;

        store
          .dispatch(Actions.UPDATE_EMAIL, values)
          .then(() => {
            successMessage(t("EMAIL_SUCCESS_UPDATED_MESSAGE"));

            let simulatedUser = JSON.parse(localStorage.getItem("simulate_user") as string);
            simulatedUser.email = values.email;
            localStorage.setItem("simulate_user", JSON.stringify(simulatedUser));

            emailFormDisplay.value = false;
            resetForm();

            setTimeout(() => {
              window.location.reload();
            }, 250);
          })
          .finally(() => {
            updateEmailButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const updatePassword = (values, { resetForm }) => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(Actions.UPDATE_PASSWORD, values)
          .then(() => {
            successMessage(t("PASSWORD_SUCCESS_UPDATED_MESSAGE"));
            passwordFormDisplay.value = false;
            resetForm();
          })
          .finally(() => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    onMounted(() => {
      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          localStorage.getItem("to_year_admin")
        );
      }, 1000);
      
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    const store = useStore();
    
    const toYear = reactive(
      computed(() => {
        return localStorage.getItem("to_year_admin");
      })
    );

    const selectedUser = reactive(
      computed(() => {
        return JSON.parse(
          localStorage.getItem("simulate_user") as string
        );
      })
    );

    const accountInfo = reactive({
      userProfile: computed(() => {
        return {
          id: selectedUser.value.id,
          user_name: selectedUser.value.user_name,
          email: selectedUser.value.email,
          ruolo_aziendale: selectedUser.value.ruolo_aziendale,
          telefono: selectedUser.value.telefono,
        }
      }),
      companyProfile: computed(() => {
        return {
          denom: selectedUser.value.denom,
          p_iva: selectedUser.value.p_iva,
        }
      }),
      companyYearlyParams: computed(() => {
        for (const yearlyParameters of selectedUser.value.yearlyParameters) {
          if (yearlyParameters.azienda_yearly_parameters.year === parseInt(toYear.value as string)) {
            return yearlyParameters;
          }
        }
        return null;
      }),
    });

    const userName = ref(accountInfo.userProfile.user_name);
    const defaultUserName = ref(userName.value);

    const userRole = ref(accountInfo.userProfile.ruolo_aziendale);
    const defaultUserRole = ref(userRole.value);

    const userPhone = ref(accountInfo.userProfile.telefono);
    const defaultUserPhone = ref(userPhone.value);

    const resetFields = () => {
      userName.value = defaultUserName.value;
      userRole.value = defaultUserRole.value;
      userPhone.value = defaultUserPhone.value;
    }

    return {
      submitButton1,
      updateUserProfile,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      translate,
      ...toRefs(accountInfo),
      userName, 
      userRole,
      userPhone,
      resetFields
    };
  },
});
